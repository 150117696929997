//农资规格管理
export const AgriculturalResourcesManagement = [{
  prop: "brand",
  label: "品牌",
  width: 100,
},
{
  prop: "model",
  label: "型号",
},

{
  prop: "specifications",
  label: "规格",
},
{
  prop: "producer",
  label: "生产商",
},
{
  prop: "guidePrice",
  label: "建议采购价",
  width: 140,

},
{
  prop: "unit",
  label: "采购单位",
  width: 80,

},
{
  prop: "otherIndicator",
  label: "其他指标",
  width: 80,
},

{
  prop: "operation",
  label: "操作",
  width: 140,
  template: true,
},
]
//农资导入总库
export const ImportAgriculturalResourcesManagement = [{
  prop: "brand",
  label: "品牌",
  width: 100,
},
{
  prop: "model",
  label: "型号",
},

{
  prop: "specifications",
  label: "规格",
},
{
  prop: "producer",
  label: "生产商",
},
{
  prop: "guidePrice",
  label: "建议采购价",
  width: 80,

},
{
  prop: "unit",
  label: "采购单位",
  width: 80,

},
{
  prop: "otherIndicator",
  label: "其他指标",
  width: 80,
},

]
//农资集采
export const NewCollectivePurchase = [{
  prop: "procurementCode",
  label: "编号",
},
{
  prop: "title",
  label: "标题",
},

{
  prop: "agriculturalType",
  label: "农资类别",
},
{
  prop: "paymentRatio",
  label: "预缴比例",
  template: true,
},
{
  prop: "status",
  label: "状态",
  template: true,
},
{
  prop: "endTime",
  label: "报名截止时间",

},
{
  prop: "createTime",
  label: "发布时间",

},
{
  prop: "operation",
  label: "操作",
  width: 250,
  template: true,
},

]
// 发起集采统计
export const CreateCollection = [{
  prop: "brand",
  label: "品牌",
},
{
  prop: "model",
  label: "型号",
},

{
  prop: "specifications",
  label: "规格",
},
{
  prop: "guidePrice",
  width: "200",
  label: "最高限价(元)",
  template: true,
},
{
  prop: "unit",
  label: "采购单位",


},
{
  prop: "otherIndicator",
  label: "其他指标",

},
]
//预览
export const PreviewList = [{
  prop: "brand",
  label: "品牌",
},
{
  prop: "model",
  label: "型号",
},

{
  prop: "specifications",
  label: "规格",
},
{
  prop: "guidePrice",
  label: "最高限价(元)",
  // template: true,
},
{
  prop: "unit",
  label: "采购单位",


},
{
  prop: "otherIndicator",
  label: "其他指标",

},
]
//  配置
export const dispositionList = [{
  prop: "brand",
  label: "品牌",
},
{
  prop: "model",
  label: "型号",
},

{
  prop: "specifications",
  label: "规格",
},
{
  prop: "producer",
  label: "生产商",
},
{
  prop: "guidePrice",
  label: "建议采购价",
  template: true,

},
{
  prop: "unit",
  label: "采购单位",

},
{
  prop: "otherIndicator",
  label: "其他指标",
  width: 140,

},

]
//集采招标信息发布

export const CallForBids = [{
  prop: "brand",
  label: "品牌",
},
{
  prop: "model",
  label: "型号",
},

{
  prop: "specifications",
  label: "规格",
},
{
  prop: "guidePrice",
  label: "最高限价(元)",
},
{
  prop: "unit",
  label: "采购单位",


},
{
  prop: "otherIndicator",
  label: "其他指标",
},
{
  prop: "quantity",
  label: "采购数量",
  template: true,
},


]
//报名信息

export const RegistrationInformation = [{
  prop: "model",
  label: "产品信息",
  template: true,
},
{
  prop: "guidePrice",
  label: "最高限价(元)",
  template: true,
},

{
  prop: "quantity",
  label: "数量",
  template: true,
},
{
  prop: "totalPrice",
  label: "费用",
  template: true,
},
]
//报名新增列表

export const InnerTableList = [{
  prop: "brand",
  label: "品牌",
},
{
  prop: "model",
  label: "型号",
},

{
  prop: "specifications",
  label: "规范",

},
{
  prop: "producer",
  label: "生产商",

},
{
  prop: "guidePrice",
  label: "最高限价(元)",

},
{
  prop: "unit",
  label: "采购单位",

},
{
  prop: "otherIndicator",
  label: "其他指标",
},
{
  prop: "quantity",
  label: "报名数量",
  template: true,
},
]
//招标公告
export const CallBids = [{
  prop: "brand",
  label: "品牌",
},
{
  prop: "model",
  label: "型号",
},

{
  prop: "specifications",
  label: "规格",
},
{
  prop: "guidePrice",
  label: "最高限价(元)",
},
{
  prop: "unit",
  label: "采购单位",


},
{
  prop: "otherIndicator",
  label: "其他指标",
},
{
  prop: "quantity",
  label: "采购数量",

},


]
//集采中标信息公示
export const MessagePublicity = [{
  prop: "brand",
  label: "品牌",
},
{
  prop: "model",
  label: "型号",
},

{
  prop: "specifications",
  label: "规格",
},
{
  prop: "producer",
  label: "生产商",
},
{
  prop: "guidePrice",
  label: "最高限价(元)",


},
{
  prop: "unit",
  label: "采购单位",
},
{
  prop: "quantity",
  label: "采购数量",

},
{
  prop: "winnerPrice",
  label: "中标价",
  template: true,
},
{
  prop: "winnerName",
  label: "中标方",
  template: true,
},
]
//集采中标信息公示详情
export const WinTheBid = [{
  prop: "brand",
  label: "品牌",
},
{
  prop: "model",
  label: "型号",
},

{
  prop: "specifications",
  label: "规格",
},
{
  prop: "producer",
  label: "生产商",
},
{
  prop: "guidePrice",
  label: "最高限价(元)",

},
{
  prop: "unit",
  label: "采购单位",
},
{
  prop: "quantity",
  label: "采购数量",

},
{
  prop: "winnerPrice",
  label: "中标价",

},
{
  prop: "winnerName",
  label: "中标方",

},
]
