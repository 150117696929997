<template>
  <div class="CallForBids">
    <h3>集采招标信息发布</h3>
    <div class="newBox">
      <el-form
        class="login-from-item"
        :model="form"
        label-position="left"
        ref="form"
        :rules="formRules"
      >
        <!-- 标题 -->
        <div class="ProductIntroduction">
          <span>标题</span>
          <el-input
            placeholder="请输入内容"
            v-model="form.tenderTitle"
            maxlength="500"
          >
          </el-input>
        </div>
        <!-- 招标单位 -->
        <div class="ProductIntroduction">
          <span>招标单位</span>
          <el-input
            placeholder="请输入内容"
            v-model="form.tenderingOrganization"
            maxlength="500"
            type="textarea"
          >
          </el-input>
        </div>
        <!-- 采购招标方式 -->
        <div class="ProductIntroduction">
          <span>采购招标方式</span>
          <el-input
            placeholder="请输入内容"
            v-model="form.tenderType"
            maxlength="500"
            type="textarea"
          >
          </el-input>
        </div>
        <!-- 报名时间 -->
        <div class="RegistrationTime">
          <div class="LProductIntroductionItem">
            <span>报名截止时间</span>
            <el-date-picker
              v-model="form.endTime"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请输入审批时间"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="brand">
          <table-page :data="tableData" :columns="columns" :border="true">
            <template slot="guidePrice" slot-scope="scope">
              <el-input
                placeholder="指导价"
                type="number"
                min="1"
                v-model="scope.row.guidePrice"
              >
              </el-input>
            </template>

            <template slot="otherIndicator" slot-scope="scope">
              <el-input
                type="textarea"
                autosize
                placeholder="请输入内容"
                v-model="scope.row.otherIndicator"
              >
              </el-input>
            </template>

            <template slot="quantity" slot-scope="scope">
              <el-input
                type="number"
                min="1"
                @input="
                  scope.row.quantity = scope.row.quantity.replace(/[^\d]/g, '')
                "
                v-model="scope.row.quantity"
              >
              </el-input>
            </template>
          </table-page>
          <div class="Tips">
            <span>
              *最终采购量以合同签订数量为准，在招标有效期内可有10%的采购浮动
            </span>
          </div>
        </div>
        <!-- 资质要求 -->
        <div class="ProductIntroduction">
          <span>资质要求</span>
          <el-input
            placeholder="请输入内容"
            v-model="form.requirements"
            maxlength="500"
            type="textarea"
          >
          </el-input>
        </div>
        <!-- 投标保证金 -->
        <div class="ContributionRatio">
          <span>投标保证金</span>
          <el-input placeholder="请输入内容" v-model="form.earnestMoney">
          </el-input>
          <span class="w">元</span>
        </div>
        <!-- 投标保证金要求 -->
        <div class="ProductIntroduction">
          <span>投标保证金要求</span>
          <el-input
            placeholder="请输入内容"
            v-model="form.explain"
            maxlength="500"
            type="textarea"
          >
          </el-input>
        </div>
        <!-- 其他说明 -->
        <div class="ProductIntroduction">
          <span>其他说明</span>
          <el-input
            placeholder="请输入内容"
            v-model="form.others"
            maxlength="500"
            type="textarea"
          >
          </el-input>
        </div>
        <!-- 相关附件 -->
        <div class="RelatedAnnex">
          <div class="ProductIntroduction">
            <span class="title">其他说明</span>
            <div class="content">
              <!-- <i class="el-icon-upload2"></i> -->
              <el-upload
                class="upload-demo"
                :action="uploadUrl"
                name="files"
                :auto-upload="false"
                :on-change="handleChangeUp"
                :file-list="fileList"
                :show-file-list="false"
              >
                <p>
                  <!-- <el-button type="primary">更换</el-button> -->
                  <i class="el-icon-upload2"></i>
                </p>
              </el-upload>
            </div>
            <div class="UpDoc">
              <div class="UpDocTitle">
                <li v-for="(item, index) in fileList" :key="index">
                  {{ item.FilesName }}
                </li>
                <!-- <li>技术说明.pdf</li>
                <li>招标说明.doc</li> -->
              </div>
              <div class="Tips">
                *请确认信息输入无误，一经发布，项目信息无法修改
              </div>
            </div>
          </div>
        </div>
        <!-- 发布 -->
        <div class="publish">
          <el-button type="primary" @click="pay()"> 发布 </el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
// 图片上传
import { uploadFile } from "@/api/fileApi";
import TablePage from "@/components/table/CallForBids-page";
import {
  selectDetailProcurement,
  saveInciteTenders,
} from "@/api/CollectivePurchase";

import { CallForBids } from "../memberCenter/CollectionMining/tableColumn";
export default {
  name: "NowWinTheBid",
  components: {
    TablePage,
  },
  data() {
    return {
      form: {
        tenderTitle: "", //标题
        tenderingOrganization: "", //招标单位
        tenderType: "", //招标采购方式
        requirements: "", //招标条件
        endTime: "", //结束时间
        earnestMoney: "", //保证金
        explain: "", //保证金说明

        others: "", //其他说明
      },
      formRules: {},
      tableData: [],
      uploadUrl: `${window.globalUrl.HOME_API_WEI}/admin/file/upload`, //上传图片的地址
      fileList: [], //上传图片的列表,
      files: [], //图片
      columns: CallForBids,
    };
  },
  created() {
    // console.log(this.$route.query.procurementId, "查看此处");
  },
  mounted() {
    this.initShow();
  },

  methods: {
    async initShow() {
      let params = this.$route.query.procurementId;
      console.log(params);
      let res = await selectDetailProcurement(params);
      this.tableData = res.data.newAgriculturalVoList;
      this.form.tenderTitle = res.data.title;
      this.form.tenderingOrganization = res.data.operatorName;
      // this.form.tenderType = res.data.others;
      // this.form.endTime = res.data.endTime;
      this.fileList = JSON.parse(res.data.files);
      // console.log(this.files);
    },
    //文件上传
    async handleChangeUp(file, fileList) {
      console.log(fileList, "这是fileList");
      let param = new FormData();
      param.append("catalog", "financeAdd");
      param.append("file", file.raw);
      let res = await uploadFile(param);
      console.log(res, "这是res");
      console.log(typeof this.fileList, "查看fileList");
      // let imgUrl = "/api/admin/file/get?ossFilePath=" + res.data.url;
      let FilesName = res.data.sourceFileName;
      let imgUrl = res.data.url;

      let item = {
        FilesName: FilesName,
        imgUrl: imgUrl,
      };
      console.log(item, "查看item");
      this.fileList.push(item);
    },
    //发布
    async pay() {
      let newFile = [];
      this.fileList.forEach((item) => {
        newFile.push({
          FilesName: item.FilesName,
          imgUrl: item.imgUrl,
        });
        console.log(newFile, "点击查看11111");
      });
      let files = JSON.stringify(newFile);
      let params = {
        procurementId: this.$route.query.procurementId,
        ...this.form,
        inviteTendersList: this.tableData,
        files,
      };
      console.log(params);
      let res = await saveInciteTenders(params);
      if (res.code == 0) {
        this.$message.success(res.msg);
        this.$router.back();
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.CallForBids {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  background: #fff;
  text-align: left;
  padding-bottom: 128px;
  h3 {
    padding: 10px 10px;
  }
  .newBox {
    width: 90%;
    margin: 0 auto;
    .ProductIntroduction {
      width: 80%;
      height: 50px;
      display: flex;
      padding-bottom: 10px;
      padding-left: 20px;
      span {
        width: 150px;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
    }
    .RegistrationTime {
      padding-left: 20px;
      display: flex;
      .LProductIntroductionItem {
        span {
          width: 127px;
          font-weight: bold;
          flex-direction: column;
          justify-content: space-around;
          display: inline-block;
        }
      }
    }
    .brand {
      margin-top: 20px;
      width: 100%;
      .Tips {
        padding-top: 20px;
        width: 100%;
        display: flex;
        justify-content: space-around;
        span {
          color: #ccc;
        }
      }
    }
    .ContributionRatio {
      width: 80%;
      height: 50px;
      display: flex;
      padding-bottom: 10px;
      padding-left: 20px;
      span {
        width: 127px;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
      .el-input {
        width: 100px;
      }
      .w {
        padding-left: 10px;
      }
    }
    .RelatedAnnex {
      .ProductIntroduction {
        width: 80%;
        height: 50px;
        display: flex;
        padding-bottom: 10px;
        .title {
          width: 150px;
          font-weight: bold;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        }
        .content {
          .el-icon-upload2 {
            font-size: 50px;
            font-weight: bold;
          }
        }
        .UpDoc {
          padding-left: 20px;
          .UpDocTitle {
            padding-top: 10px;
            display: flex;
            list-style: none;
            li {
              padding: 0px 8px;
              text-decoration: underline;
            }
          }
          .Tips {
            padding-top: 10px;
            color: #ec808d;
          }
        }
      }
    }
    .publish {
      padding-top: 20px;
      width: 100%;
      display: flex;
      justify-content: space-around;
      .el-button {
        width: 150px;
      }
    }
  }
}
</style>
